<template>
  <div class="w-full">
    <div class="max-w-screen-2xl m-auto">
      <div>
        <!-- class="login_body bg-cover bg-center h-[100vh] relative" -->
        <div class="absolute top-0 left-0 w-full h-full">
          <img
            src="@/assets/Signup.webp"
            alt="Signup background image"
            class="w-full h-full object-cover"
          />
        </div>
        <div
          class="absolute top-[14%] md:top-[8%] xl:top-[10%s] 2xl:top-[18%] left-[5%] md:left-[30%] lg:left-[50%] xl:left-[55%] 2xl:left-[60%]"
        >
          <form
            class="bg-white p-[1rem] w-[22rem] md:w-[30rem] h-[35rem] rounded-lg"
            @submit.prevent="loginHandler"
          >
            <div class="w-[15rem] m-auto pt-[4rem]">
              <img src="@/assets/logo.webp" alt="logo" />
            </div>
            <div class="pt-[4rem] flex justify-center" v-if="toggleInfo">
              <span
                class="text-sm text-black bg-[#fdc42c] p-[16.2px] rounded-l-full"
                >+91</span
              >
              <input
                type="number"
                class="w-[13rem] md:w-[16rem] outline-none text-black border-[1px] p-[.7rem] border-[#fdc42c] shadow-md"
                placeholder="Enter your phone number"
              />
              <button class="bg-[#fdc42c] rounded-r-full">
                <img
                  src="@/assets/Sign-in.webp"
                  alt="SignIn"
                  class="w-[40%] m-auto"
                />
              </button>
            </div>
            <div class="pt-[4rem]" v-else>
              <input
                type="email"
                placeholder="Enter Email"
                id="otp_receiver"
                class="border-[1px] p-[.7rem] w-[20rem] md:w-[23rem] rounded-full border-[#fdc42c] shadow-md outline-none"
                v-model="email"
                required
              />
              <div class="flex mt-2 justify-between ml-[3rem] mr-[3rem]">
                <div class="font-bold text-xs">
                  {{ this.otpSuccessMessage }}
                </div>
                <div v-if="email != ''" @click.prevent="sendOtp">
                  <button
                    class="text-sm font-bold mr-auto hover:text-yellow-500"
                    type="submit"
                  >
                    Send OTP
                  </button>
                </div>
              </div>
            </div>
            <!-- <div
              class="mt-2 mr-[12rem] md:mr-[13rem] text-sm cursor-pointer"
              @click="toggleInfo = !toggleInfo"
            >
              {{
                toggleInfo ? "continue with email?" : "continue with number?"
              }}
            </div> -->

            <div class="m-auto pt-[1.2rem]">
              <input
                type="tel"
                placeholder="Enter OTP"
                class="border-[1px] p-[.7rem] w-[20rem] md:w-[23rem] rounded-full border-[#fdc42c] shadow-md outline-none"
                maxlength="4"
                required
                onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                v-model="otpNumber"
              />
            </div>
            <div
              class="text-red-500 text-sm mt-4 text-start ml-[3rem]"
              v-if="wrongOtpMessage != ''"
            >
              {{ wrongOtpMessage }}
            </div>
            <button
              class="mt-[1.5rem] bg-[#fdc42c] w-[8rem] h-[2.5rem] rounded-full text-center font-semibold"
              :class="[
                loginProcess
                  ? 'pointer-events-none opacity-60'
                  : 'pointer-events-auto opacity-100',
              ]"
              type="submit"
            >
              <span>Login</span>

              <font-awesome-icon
                :class="[loginProcess ? 'visible' : 'hidden']"
                icon="fa-solid fa-spinner fa-spin"
              />
            </button>

            <div class="w-max m-auto cursor-pointer">
              <div class="mt-2">or</div>
              <a href="/sign-up">
                <div class="mt-2 underline">Register</div>
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      toggleInfo: false,
      otpSuccessMessage: "",
      email: "",
      enteredOtp: "",
      errorStatus: false,
      otpNumber: "",
      loginProcess: false,
      wrongOtpMessage: "",
    };
  },
  created() {},
  methods: {
    ...mapMutations(["setUserInfo"]),
    async sendOtp() {
      let data = {
        email: this.email,
      };
      await axios
        .post(`${process.env.VUE_APP_API}/otp_send/`, data)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.otpSuccessMessage = resp.data.message;
          } else {
            this.otpSuccessMessage = resp.data.message;
          }
          setTimeout(() => {
            this.otpSuccessMessage = "";
          }, 2000);
        })
        .catch((error) => {
          this.otpSuccessMessage = "something weng wrong";
          console.log(error);
        });
    },
    async loginHandler() {
      this.loginProcess = true;
      let data = {
        email: this.email,
        password: this.otpNumber,
      };
      await axios
        .post(`${process.env.VUE_APP_API}/login/`, data)
        .then((resp) => {
          if (resp.data.status == 200) {
            setTimeout(() => {
              this.setUserInfo(resp.data.data);
              this.$router.push({
                path: "/",
                name: "home",
              }); 
            }, 1000);
          } else {
            this.wrongOtpMessage = "Incorrect Credentials";
            setTimeout(() => {
              this.wrongOtpMessage = "";
            }, 1500);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loginProcess = false;
        });
    },
  },
};
</script>

<style scoped>
.login_body {
  background-image: url("@/assets/Signup.webp");
}
</style>

import { createRouter, createWebHistory } from "vue-router";
import LoginView from "@/views/LoginView.vue";
import CourseDetail from "@/views/CourseDetail.vue";
import CourseView from "@/views/CourseView.vue";
import TestView from "@/views/TestView.vue";
import ProfileView from "@/views/ProfileView.vue";
import SettingView from "@/views/SettingView.vue";
import EnrolledCoursesView from "@/views/EnrolledCoursesView.vue"
import store from "@/store";

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/enrolledcourses",
    name: "EnrolledCourses",
    component: EnrolledCoursesView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/sign-up",
    name: "SignUP",
    component: () =>
      import(/* webpackChunkName: "signup" */ "../views/SignUpView.vue"),
  },
  {
    path: "/coursedetail/:course_slug",
    name: "coursedetail",
    component: CourseDetail,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/courseview/:slug",
    name: "courseview",
    component: CourseView,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/profile",
    name: "profileview",
    component: ProfileView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting",
    name: "setting",
    component: SettingView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/test",
    name: "testview",
    component: TestView,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router;

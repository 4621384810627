<template>
  <div>
    <Header />
    <div class="w-full">
      <div class="max-w-screen-2xl m-auto">
        <div class="p-[.5rem]">
          <div
            class="w-full bg-white min-h-[400px] rounded-md shadow-md my-5 overflow-x-auto lg:max-h-[calc(100vh-370px)] lg:overflow-y-auto"
          >
            <table class="relative w-full">
              <thead class="sticky top-0 bg-white shadow">
                <tr>
                  <th
                    class="px-6 py-4 text-xs uppercase whitespace-nowrap font-semibold text-left"
                  >
                    Name
                  </th>
                  <th
                    class="px-6 py-4 text-xs uppercase whitespace-nowrap font-semibold text-left"
                  >
                    Email
                  </th>
                  <th
                    class="px-6 py-4 text-xs uppercase whitespace-nowrap font-semibold text-left"
                  >
                    Course
                  </th>
                  <th
                    class="px-6 py-4 text-xs uppercase whitespace-nowrap font-semibold text-left"
                  >
                    Payment TYPE
                  </th>
                  <th
                    class="px-6 py-4 text-xs uppercase whitespace-nowrap font-semibold text-left"
                  >
                    Payment status
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="border-b"
                  v-for="course in courseList"
                  :key="course.id"
                >
                  <td class="px-6 py-4 text-xs whitespace-nowrap text-left">
                    {{ userInfo.user.display_name }}
                  </td>
                  <td
                    class="px-6 py-4 text-xs whitespace-nowrap text-left cursor-pointer"
                  >
                    {{ userInfo.user.email }}
                  </td>
                  <template v-for="courses in course.course" :key="courses.id">
                    <td class="px-6 py-4 text-xs whitespace-nowrap text-left">
                      {{ courses.title }}
                    </td>
                    <td class="px-6 py-4 text-xs whitespace-nowrap text-left">
                      {{ courses.free ? "FREE" : "" }}
                    </td>
                    <td class="px-6 py-4 text-xs whitespace-nowrap text-left">
                      {{ courses.free ? "NA" : "" }}
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/component/Header.vue";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  components: {
    Header,
  },
  data() {
    return {
      courseList: [],
    };
  },
  created() {
    this.userPermissionList();
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    async userPermissionList() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/userpermissionlist/?Key=${this.userInfo.token}`
        )
        .then((resp) => {
          this.courseList = resp.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
  border-radius: 5px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #110b7c;
  color: white;
}
</style>

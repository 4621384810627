<template>
  <div>
    <Header />
    <div class="max-w-screen-2xl m-auto">
        <div class="mt-8 text-3xl font-bold underline">QUIZ</div>
      <form class="mt-8 ml-8" v-if="!quizResponseToggle" @submit.prevent="quizResponseToggle = !quizResponseToggle">
        <div
          class="w-[50rem] h-[15rem] border p-[1rem] shadow-md mb-4"
          v-for="quiz in quizData"
          :key="quiz.count"
        >
          <div class="flex">
            <div class="mr-4">{{ quiz.count }}.</div>
            <p class="mb-4">
              {{ quiz.question }}
            </p>
          </div>
          <div>
            <div class="flex items-center gap-1 mb-4">
              <input type="checkbox" class="mr-2" />
              <label for="">{{ quiz.optionA }}</label>
            </div>
            <div class="flex items-center gap-1 mb-4">
              <input type="checkbox" class="mr-2"/>
              <label for="">{{ quiz.optionB }}</label>
            </div>
            <div class="flex items-center gap-1 mb-4">
              <input type="checkbox" class="mr-2" />
              <label for="">{{ quiz.optionC }}</label>
            </div>
            <div class="flex items-center gap-1 mb-4">
              <input type="checkbox" class="mr-2" />
              <label for="">{{ quiz.optionD }}</label>
            </div>
          </div>
        </div>
        <div class="mt-8 mb-8">
          <button class="bg-yellow-500 py-2 px-8 rounded cursor-pointer" type="submit">Submit</button>
        </div>
      </form>
      <div v-else class="mt-8 font-bold text-xl">
        <div>Thankyou for taking test your Score is 80 percentage.</div>
         <button class="mt-8 border bg-yellow-500 p-2 rounded" @click="quizResponseToggle = !quizResponseToggle">GoBack</button>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/component/Header.vue";
export default {
  name: "TestView",
  components: {
    Header,
  },
  data() {
    return {
      quizResponseToggle: false,
      quizData: [
        {
          count: 1,
          question:
            "Which of the following is golden rule for interface design?",
          optionA: "Place the user in control",
          optionB: "Reduce the user’s memory load",
          optionC: "Make the interface consistent",
          optionD: "All of the mentioned",
        },
        {
          count: 2,
          question:
            "Which of the following is not a design principle that allow the user to maintain control?",
          optionA: "Provide for flexible interaction",
          optionB: "Allow user interaction to be interrupt-able and undo-able",
          optionC: "Show technical internals from the casual user",
          optionD:
            " Design for direct interaction with objects that appear on the screen",
        },
        {
          count: 3,
          question:
            "Which of the following is not a user interface design process?",
          optionA: "User, task, and environment analysis and modeling",
          optionB: "Interface design",
          optionC: "Knowledgeable, frequent users",
          optionD: "Interface validation",
        },
        {
          count: 4,
          question:
            "When users are involved in complex tasks, the demand on __________ can be significant.",
          optionA: "short-term memory",
          optionB: " shortcuts",
          optionC: "objects that appear on the screen",
          optionD: "all of the mentioned",
        },
        {
          count: 5,
          question:
            "Which of the following option is not considered by the Interface design?",
          optionA: "the design of interfaces between software components",
          optionB:
            "the design of interfaces between the software and human producers and consumers of information",
          optionC: "the design of the interface between two computers",
          optionD: "all of the mentioned",
        },
      ],
    };
  },
};
</script>

<style scoped></style>

<template>
  <nav></nav>
  <router-view />
</template>

<style lang="scss">
#app {
  font-family: "source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif;";
  -webkit-font-smoothing: "montserrat";
  -moz-osx-font-smoothing: "montserrat";
  text-align: center;
  color: #2c3e50;
}
</style>

import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

const ls = new SecureLS({ isCompression: false });

export default createStore({
  state: {
    user_info: null,
    isAuthenticated: false,
  },
  getters: {
    userInfo: (state) => state.user_info,
    isAuthenticated: (state) => state.isAuthenticated,
  },
  mutations: {
    setUserInfo: (state, data) => {
      if (data) {
        state.user_info = data;
        state.isAuthenticated = true;
      } else {
        state.user_info = null;
        state.isAuthenticated = false;
      }
    },
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});

<template>
  <div>
    <div class="mt-[2rem] ml-6 md:ml-[5rem] 2xl:ml-0 mr-[4rem] mb-8 relative">
      <h1 class="w-max font-extrabold text-sm md:text-xl lg:text-3xl">
        ONLINE DEGREE AND CERTIFICATE COURSES
      </h1>
      <div
        class="h-[3px] bg-yellow-400 mt-3 w-[20rem] md:w-[28rem] lg:w-[42rem]"
      ></div>
      <!--tabs-->
      <div class="flex gap-5 mt-8 cursor-pointer">
        <div
          class="font-semibold p-1 md:py-2 md:px-4 text-[#acacac] rounded-md text-sm border text-center"
          @click="activeTabs = 'recommended'"
          :class="
            activeTabs == 'recommended'
              ? 'bg-black text-white border-0'
              : 'text-[#acacac]'
          "
        >
          Recommended
        </div>
        <div
          class="font-semibold p-1 md:py-2 md:px-4 text-[#acacac] rounded-md text-sm border"
          @click="
            activeTabs = 'OnlineDegree';
            tabRefresh();
          "
          :class="
            activeTabs == 'OnlineDegree'
              ? 'bg-black text-white border-0'
              : 'text-[#acacac]'
          "
        >
          Online Degrees
        </div>
        <div
          class="font-semibold p-1 md:py-2 md:px-4 rounded-md text-[#acacac] text-sm border"
          @click="activeTabs = 'certification'"
          :class="
            activeTabs == 'certification'
              ? 'bg-black text-white border-0'
              : 'text-[#acacac]'
          "
        >
          Certification
        </div>
      </div>
      <!--tabs-->
      <!--Recommended-->
      <div class="flex flex-wrap gap-5" v-if="activeTabs == 'recommended'">
        <div class="mt-4" v-for="course in recomdInfo" :key="course.id">
          <a :href="`/coursedetail/${course.slug}`">
            <div
              class="w-[20rem] h-[20rem] shadow-lg border border-gray-200 cursor-pointer rounded-md"
            >
              <div class="p-2">
                <img
                  :src="course.Image"
                  alt="banner"
                  class="h-[10rem] object-cover rounded-md"
                />
              </div>
              <div class="mt-4 text-start ml-2 text-lg font-bold line-clamp-2">
                <p>
                  {{ course.title }}
                </p>
                <p class="text-sm font-normal line-clamp-2">
                  {{ course.short_title }}
                </p>
              </div>
              <div class="mt-4 text-start ml-2 text-[#acacac] text-sm">
                Beginner {{ course.max_durations }} hrs
              </div>
            </div>
          </a>
        </div>
      </div>
      <!--Recommended-->
      <!--onlineDegree -->
      <div v-if="activeTabs == 'OnlineDegree' && courseInfo.length > 0">
        <div class="">
          <div
            class="flex gap-8 flex-wrap justify-start items-center w-full mt-8"
          >
            <div
              class="h-[392px] rounded-md relative"
              v-for="course in courseInfo.slice(0, 12)"
              :key="course.id"
            >
              <div
                class="w-[20rem] shadow-md rounded-md p-3 border border-gray-200 h-full"
              >
                <div class="relative">
                  <div class="w-full">
                    <img
                      :src="course.university.img"
                      :alt="course.university.name"
                      class="h-[11rem] rounded-md object-cover"
                    />
                  </div>
                  <div class="absolute bottom-[-24%]">
                    <div class="flex p-2 gap-2">
                      <div
                        class="rounded-md shadow w-[10rem] h-[4rem] bg-white m-auto flex items-center justify-center"
                      >
                        <img
                          :src="course.university.black_logo"
                          alt="smallcard"
                          class="object-contain p-2"
                        />
                      </div>
                      <div
                        class="bg-[#fec52d] w-[3.5rem] h-[3.5rem] rounded-full text-center flex justify-center items-center flex-col"
                      >
                        <div>
                          <font-awesome-icon
                            icon="fa-solid fa-wifi"
                            class="text-xl cursor-pointer"
                          />
                        </div>
                        <div class="text-xs">{{ course.elective.mode }}</div>
                      </div>
                      <div
                        class="bg-[#fec52d] w-[3.5rem] h-[3.5rem] rounded-full text-center flex justify-center items-center flex-col"
                      >
                        <div>
                          <font-awesome-icon
                            icon="fa-solid fa-clock"
                            class="text-xl cursor-pointer"
                          />
                        </div>
                        <div class="text-xs">{{ course.program.duration }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col justify-around">
                  <div class="flex flex-col gap-1">
                    <div
                      class="w-[15rem] mt-[3rem] text-start pl-2 text-lg font-semibold line-clamp-2"
                    >
                      {{ course.program.name }} - {{ course.elective.name }}
                    </div>
                    <div class="text-start pl-2 text-[#919ba4] text-sm">
                      {{ course.university.name }}
                    </div>
                  </div>
                  <div
                    class="absolute flex gap-2 p-1 justify-center h-[58px] bottom-0 w-full left-0"
                  >
                    <a
                      :href="`https://learn.online/${course.university.slug}/${course.category.slug}/${course.program.slug}/${course.elective.slug}`"
                      target="_blank"
                    >
                      <div
                        class="w-[9rem] border border-[#fec52d] font-semibold py-2 text-center text-xs rounded"
                      >
                        View Program
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="mx-auto">
              <a href="https://learn.online/" target="_blank">
                <button
                  class="px-4 py-2 bg-[#fec52d] w-[220px] rounded-md font-semibold"
                >
                  View More
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!--OnlineDegree-->
      <!--certificate-->
      <div>
        <div class="flex flex-wrap gap-5" v-if="activeTabs == 'certification'">
          <template v-if="filteredRecomdInfo != '' ">
            <div
              class="mt-4"
              v-for="course in filteredRecomdInfo"
              :key="course.id"
            >
              <a :href="`/coursedetail/${course.slug}`">
                <div
                  class="w-[20rem] h-[20rem] shadow-lg border border-gray-200 cursor-pointer rounded-md"
                >
                  <div class="p-2">
                    <img
                      :src="course.Image"
                      alt="banner"
                      class="h-[10rem] object-cover rounded-md"
                    />
                  </div>
                  <div
                    class="mt-4 text-start ml-2 text-lg font-bold line-clamp-2"
                  >
                    <p>
                      {{ course.title }}
                    </p>
                    <p class="text-sm font-normal line-clamp-2">
                      {{ course.short_title }}
                    </p>
                  </div>
                  <div class="mt-4 text-start ml-2 text-[#acacac] text-sm">
                    Beginner {{ course.max_durations }} hrs
                  </div>
                </div>
              </a>
            </div>
          </template>
          <div v-else class="m-auto mt-10">
            <font-awesome-icon
              :icon="['fas', 'spinner']"
              spin
              class="text-4xl"
            />
          </div>
        </div>
      </div>
      <!--certificate-->
    </div>
  </div>
</template>

<script>
import axios from "axios";

import "slick-carousel";

export default {
  name: "OnlineDegree",
  props: ["searchBar"],
  data() {
    return {
      courseInfo: [],
      recomdInfo: [],
      online_degree: "online_components",
      activeTabs: "certification",
      // activeTabs: "OnlineDegree",
    };
  },
  created() {},
  async mounted() {
    await this.courseData();
    this.recommnededData();
  },
  methods: {
    async tabRefresh() {
      await this.courseData();
    },
    async courseData() {
      await axios
        .get(`https://api.learn.online/program-elective/list/`)
        .then((resp) => {
          if (resp.status == 200) {
            this.courseInfo = resp.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async recommnededData() {
      await axios
        .get(`${process.env.VUE_APP_API}/course_list/`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.recomdInfo = resp.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    filteredRecomdInfo() {
      if (this.recomdInfo !== "" && this.searchBar) {
        return this.recomdInfo.filter((item) =>
          item.title.toUpperCase().includes(this.searchBar.toUpperCase())
        );
      } else {
        return this.recomdInfo;
      }
    },
  },
};
</script>

<style scoped>
.cardAnimation {
  transition: transform 0.2s;
}

.image {
  height: 100px;
  background: gray;
}
</style>

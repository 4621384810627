<template>
  <div class="w-full h-[4rem] shadow-lg relative">
    <div class="max-w-screen-2xl m-auto">
      <div class="flex items-center pt-[12px]">
        <!--first part-->
        <div class="flex items-center ml-4 md:ml-[4rem] 2xl:ml-0">
          <a href="/">
            <div class="w-[28%] lg:w-[14%] xl:w-[8%]">
              <img src="@/assets/logo.webp" alt="logo" />
            </div>
          </a>
          <!-- <div
            class="w-[10rem] text-[#8a8a8a] text-xs ml-5 font-medium hidden md:block"
          >
            My Classroom
          </div> -->
        </div>
        <!--first part-->
        <!--Seconde Part-->
        <div class="flex items-center mr-4 md:mr-[4rem] 2xl:mr-0">
          <!-- Class Room -->
          <!-- <div
            class="flex items-center justify-center py-[.3rem] px-[.3rem] bg-[#f9c328] rounded-lg mt-2 cursor-pointer"
          >
            <div class="w-[10%]">
              <img src="@/assets/My-class-room.webp" alt="classRoom" />
            </div>
            <div class="w-[7rem] font-semibold text-sm ml-1">My Classroom</div>
          </div> -->
          <div
            class="mt-2 md:ml-[6rem] cursor-pointer w-[8rem] md:w-[10rem] hidden md:flex items-center"
            @click="toggleBar = !toggleBar"
          >
            <div class="w-10">
              <img
                src="@/assets/ProfileAvatar.png"
                alt="profileAvatar"
                class="w-10 object-contain bg-gray-100 rounded-full p-1"
              />
            </div>
            <div
              class="self-center text-sm font-semibold ml-2 capitalize"
              v-if="profileDetails"
            >
              {{ profileDetails.display_name }}
            </div>
            <div class="self-center ml-2">
              <font-awesome-icon
                icon="fa-solid fa-chevron-down"
                class="text-sm cursor-pointer"
              />
            </div>
          </div>
          <div class="md:hidden pt-4 ml-4" @click="toggleBar = !toggleBar">
            <font-awesome-icon
              icon="fa-solid fa-bars"
              class="text-2xl cursor-pointer"
              v-if="!toggleBar"
            />
            <font-awesome-icon
              icon="fa-solid fa-xmark"
              class="text-2xl cursor-pointer"
              v-else
            />
          </div>
          <div
            class="absolute top-[4.1rem] left-0 md:left-[62%] xl:left-[75%] w-full md:w-[20rem] z-10 cursor-pointer"
            v-if="toggleBar"
          >
            <div class="shadow-md bg-white">
              <a href="/profile">
                <div
                  class="text-start pl-4 pt-2 font-bold p-[.5rem] border-b md:border-none"
                >
                  Profile
                </div>
              </a>
              <div
                class="text-start pl-4 font-bold p-[.5rem] border-b md:border-none"
              >
                Payment details
              </div>

              <a href="/setting">
                <div
                  class="text-start pl-4 font-bold p-[.5rem] border-b md:border-none"
                >
                  Account Settings
                </div>
              </a>
              <div
                class="text-start pl-4 font-bold p-[.5rem] border-b md:border-none"
                @click.prevent="logout"
                v-if="isAuthenticated"
              >
                Log out
              </div>
            </div>
          </div>
        </div>
        <!--Seconde Part-->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "HeaderView",
  data() {
    return {
      toggleBar: false,
      profileDetails: [],
    };
  },
  created() {
    this.profileInfo();
  },
  computed: {
    ...mapGetters(["userInfo", "isAuthenticated"]),
  },
  methods: {
    ...mapMutations(["setUserInfo"]),
    logout() {
      this.setUserInfo(null);
      this.$router.push({
        path: "/login",
      });
    },
    async profileInfo() {
      if (this.isAuthenticated && this.userInfo) {
        await axios
          .get(
            `${process.env.VUE_APP_API}/profile_detail/?Key=${this.userInfo.token}`
          )
          .then((resp) => {
            if (resp.data.status == 200) {
              this.profileDetails = resp.data.data[0];
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped></style>
1

import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "jquery";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faBars,
  faXmark,
  faChevronDown,
  faChevronUp,
  faTrashCan,
  faSpinner,
  faWifi,
  faClock,
  faAngleRight,
  faAngleLeft,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faBars,
  faXmark,
  faChevronDown,
  faChevronUp,
  faTrashCan,
  faSpinner,
  faWifi,
  faClock,
  faAngleRight,
  faAngleLeft,
  faPenToSquare
);

const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(store).use(router).mount("#app");

<template>
  <div>
    <Header />
    <div class="w-full">
      <div class="max-w-screen-2xl m-auto">
        <div class="w-max mt-4 ml-4 text-2xl text-[#fdc42c] font-semibold">
          Profile
        </div>
        <div class="flex justify-center">
          <div class="mt-4 shadow-lg border-[1px] w-max h-[26rem]">
            <div class="m-[1.5rem] w-max">
              <img
                src="@/assets/profilePhoto.png"
                alt="profilePhonto"
                class="w-[9rem] h-[9rem] rounded-[50%]"
              />
            </div>
            <div class="text-start m-[1.5rem] font-semibold text-xl w-max">
              Account Information
            </div>
            <div class="flex flex-col m-[1.5rem] gap-2 flex-wrap justify-between w-[18rem] md:w-[20rem] ">
              <div class="w-[12rem] flex">
                <div class="text-xl font-medium">FirstName:</div>
                <div class="pl-6 text-xl">{{ profilePage.display_name }}</div>
              </div>
              <div class="w-[12rem] flex">
                <div class="text-xl font-medium">Email:</div>
                <div class="pl-6 text-xl">{{ profilePage.email }}</div>
              </div>
              <div class="w-[12rem] flex">
                <div class="text-xl font-medium">Phone:</div>
                <div class="pl-6 text-xl">{{ profilePage.phone_number }}</div>
              </div>
            </div>
            <div class="bg-yellow-400 cursor-pointer showdow-md rounded-lg border w-max px-4 py-1 ml-auto mr-8">Edit</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Header from "@/component/Header.vue";
export default {
  components: {
    Header,
  },
  data() {
    return {
      profilePage: [],
    };
  },
  created() {
    this.profileInfo();
  },
  computed: {
    ...mapGetters(["userInfo", "isAuthenticated"]),
  },
  methods: {
    async profileInfo() {
      if (this.isAuthenticated) {
        await axios
          .get(
            `${process.env.VUE_APP_API}/profile_detail/?Key=${this.userInfo.token}`
          )
          .then((resp) => {
            if (resp.data.status == 200) {
              this.profilePage = resp.data.data[0];
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped></style>

<template>
  <div class="w-full">
    <div class="max-w-screen-2xl m-auto flex">
      <!--left Nav bar-->
      <div class="">
        <div
          :class="[activeNabBar ? 'left-0' : '-left-full']"
          class="fixed top-12 lg:top-0 w-full lg:-left-0 2xl:relative lg:w-[270px] h-[100vh] bg-[#fdc42c] border-[#fdc42c] border-l-4 p-5 sm:px-0 shrink-0 transition-all duration-500 ease-in-out overflow-y-auto"
          style="z-index: 100"
        >
          <i
            class="block sm:hidden absolute top-5 right-5 fa-solid fa-xmark text-2xl text-gray-100 cursor-pointer"
          ></i>
          <a href="/">
            <div
              class="flex flex-row sm:justify-center lg:justify-start items-center"
            >
              <img
                src="@/assets/logo.webp"
                alt="learnOnline logo"
                class="hidden lg:block p-6 w-[12rem]"
              />
            </div>
          </a>

          <div class="flex flex-col gap-2 mt-4 h-[calc(100vh-470px)]">
            <div v-for="tabs in courseViewData.section_lesson" :key="tabs.id">
              <!--tabs-->
              <div
                class="relative flex flex-row justify-between items-center gap-3 px-4 sm:px-0 py-2.5 lg:px-6 border-b border-black cursor-pointer"
                @click="toggleTab(tabs.id)"
              >
                <div class="flex items-center gap-2">
                  <div class="w-[1.5rem]">
                    <img src="@/assets/1.webp" alt="logo" />
                  </div>
                  <div
                    class="flex sm:hidden lg:flex text-xs sm:text-sm text-start line-clamp-1"
                  >
                    {{ tabs.title }}
                  </div>
                </div>
                <div>
                  <img
                    src="@/assets/rightArrow.png"
                    alt="rightArrow"
                    class="w-[1rem]"
                  />
                </div>
              </div>
              <!--tabs-->
              <!--tabsData-->
              <div v-if="activeTab == tabs.id">
                <div
                  v-for="innerTab in tabs.Section"
                  :key="innerTab.id"
                  class="p-[10px]"
                >
                  <div
                    class="flex items-center justify-between gap-3 px-4 sm:px-0 py-2.5 lg:px-2 cursor-pointer h-[3rem] w-[15rem] rounded-md"
                    @click="contentUpdate(innerTab)"
                    :class="[activeInnerTab == innerTab.id ? 'bg-white ' : '']"
                  >
                    <!-- @click="toggleInnerTab(innerTab.id)" -->
                    <div class="w-[10rem] text-xs text-start line-clamp-1">
                      {{ innerTab.title }}
                    </div>
                    <div>
                      <img
                        src="@/assets/rightArrow.png"
                        alt="rightArrow"
                        class="w-[1rem]"
                      />
                    </div>
                  </div>
                  <!--innerTabs-->
                  <!-- <div
                    v-if="activeInnerTab == innerTab.id"
                    class="flex items-center justify-between gap-3 px-4 sm:px-0 py-2.5 lg:px-6 cursor-pointer"
                  >
                    <li class="listStyle">
                   
                    </li>
                    <div class="w-[1.5rem]">
                      <img src="@/assets/1.webp" alt="videoApp" />
                    </div>
                  </div> -->
                  <!--innerTabs-->
                </div>
              </div>
              <!--tabsData-->
            </div>
            <router-link to="/test">
              <div
                class="w-max mt-[1rem] m-auto bg-black text-white text-center px-[3rem] py-[.3rem] text-xl rounded-md cursor-pointer"
              >
                Take Exam
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <!--left Nav bar-->
      <!--centerLayout-->

      <div
        class="w-full lg:w-[calc(100%-270px)] 2xl:w-full lg:ml-[270px] 2xl:ml-0 flex flex-col 2xl:overflow-y-auto"
      >
        <!--centerHeader-->
        <div class="flex justify-between items-center h-[3rem] shadow-md">
          <div class="ml-[2rem] text-[#bfbfbf] hidden lg:block">
            My Courses / {{ courseViewData.slug }}
          </div>
          <div class="w-[5rem] ml-4 lg:hidden">
            <img src="@/assets/logo.webp" alt="logo" />
          </div>
          <div class="flex mr-[2rem]">
            <div class="flex gap-5 items-center lg:hidden">
              <div class="text-md text-xs font-bold bg-[#f2b214] rounded px-2">
                My Classroom
              </div>
              <div @click="activeNabBar = !activeNabBar">
                <font-awesome-icon
                  icon="fa-solid fa-bars"
                  class="text-xl cursor-pointer"
                />
              </div>
            </div>
            <div
              class="w-[1.5rem] hidden lg:block cursor-pointer"
              @click="profileToggle = !profileToggle"
            >
              <img src="@/assets/ProfileAvatar.png" alt="ProfileAvatar" />
            </div>
            <div
              class="absolute top-[3.035rem] left-0 md:left-[58%] lg:left-[68%] xl:left-[76%] 2xl:left-[73%] xxl:left-[67%] w-full md:w-[20rem] z-50 cursor-pointer"
              v-if="profileToggle"
            >
              <div class="shadow-md bg-white">
                <a href="/profile">
                  <div
                    class="text-start pl-4 pt-2 font-bold p-[.5rem] border-b md:border-none"
                  >
                    Profile
                  </div>
                </a>
                <div
                  class="text-start pl-4 font-bold p-[.5rem] border-b md:border-none"
                >
                  Payment details
                </div>

                <a href="/setting">
                  <div
                    class="text-start pl-4 font-bold p-[.5rem] border-b md:border-none"
                  >
                    Account Settings
                  </div>
                </a>
                <div
                  class="text-start pl-4 font-bold p-[.5rem] border-b md:border-none"
                  @click.prevent="logout"
                  v-if="isAuthenticated"
                >
                  Log out
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--centerHeader-->
        <!--videoDiv-->
        <div class="">
          <div class="h-[94.9vh] flex relative">
            <!--content-->
            <div class="p-[1rem] basis-full overflow-y-auto">
              <div class="text-start text-2xl font-bold">{{ courseTitle }}</div>
              <div
                class="text-start mt-4 leading-8 not-italic text-[16px] text-[#242424] font-normal tracking-tight px-4"
                v-html="courseContent"
              ></div>
              <div class="flex justify-between mr-4 mt-4 pb-5">
                <button
                  class="bg-yellow-500 px-4 py-1 text-xl rounded text-center font-bold"
                  @click="PrevButtonClick"
                >
                  Prev
                </button>
                <button
                  class="bg-yellow-500 px-4 py-1 text-xl rounded text-center font-bold"
                  @click="nextButtonClick"
                >
                  Next
                </button>
              </div>
            </div>

            <!--content-->
            <!--notes-->
            <div
              class="w-[2.5rem] h-[2.5rem] rounded-full mt-4 mr-6 absolute right-0 z-30 p-2 bg-white"
              @click="rightSidebarToggle = !rightSidebarToggle"
            >
              <font-awesome-icon
                icon="fa-solid fa-bars"
                class="text-xl cursor-pointer"
              />
            </div>
            <div
              v-if="rightSidebarToggle"
              class="bg-yellow-500 shadow-lg z-10 h-full pt-8 basis-6/12 absolute md:relative md:block"
            >
              <div class="font-bold text-start pl-4 mt-4 text-2xl">Notes</div>
              <form @submit.prevent="notesHandler" class="p-2">
                <div class="w-full md:w-[23rem]">
                  <input
                    type="text"
                    name="title"
                    class="outline-none w-[18rem] md:w-[22rem] p-2 rounded-t-lg"
                    placeholder="Title*"
                    v-model="notesTitle"
                    required
                  />
                  <textarea
                    name="notes"
                    rows="4"
                    cols="37"
                    class="outline-none p-2 rounded-b-lg w-[18rem] md:w-[22rem]"
                    v-model="notesInput"
                    placeholder="Take a note*"
                    required
                  ></textarea>
                </div>
                <button
                  class="w-max bg-black text-white px-8 py-2 font-bold mt-4 rounded-full"
                  type="sumbit"
                >
                  Submit
                </button>
              </form>
              <!-- notesCard -->
              <div class="mt-4 ml-4 h-[18rem] overflow-y-auto" v-if="notesData">
                <div
                  class="flex flex-col justify-center items-center w-max"
                  v-for="(notes, index) in notesData"
                  :key="index"
                >
                  <div class="bg-white rounded-lg w-[20rem] border mt-3">
                    <div class="flex items-center justify-between">
                      <div class="pt-2 text-start pl-4 font-bold">
                        {{ notes.title }}
                      </div>
                      <div class="pr-4">
                        <span @click="deleteNotes">
                          <font-awesome-icon
                            icon="fa-solid fa-trash-can"
                            class="text-sm cursor-pointer"
                        /></span>
                        <span class="pl-2" @click="editNotes(notes)">
                          <font-awesome-icon
                            icon="fa-solid fa-pen-to-square"
                            class="text-sm cursor-pointer"
                          />
                        </span>
                      </div>
                    </div>
                    <div class="px-[1rem] text-start h-max pb-8">
                      {{ notes.notes }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- notesCard -->
            </div>
            <!--notes-->
          </div>
        </div>
        <!--videoDiv-->
        <!--deatailsDiv-->
        <!-- <div class="mt-[1rem] ml-[1.5rem] mr-[10rem]">
          <div
            class="ml-[10px] flex gap-5 border-b-2 pb-2 justify-between md:justify-center lg:justify-start"
          >
            <div class="flex items-center gap-2 mr-[4rem]">
              <div class="w-[1.3rem]">
                <img src="@/assets/1.webp" alt="overView" />
              </div>
              <div>Overview</div>
            </div>
            <div class="flex items-center gap-2">
              <div class="w-[1.3rem]">
                <img src="@/assets/1.webp" alt="notebook" />
              </div>
              <div>Notebook</div>
            </div>
          </div>
          <div class="flex flex-col lg:flex-row">
            <div class="lg:border-r-4">
              <div class="mt-[1rem]">
                <div class="w-max mb-[1rem] pl-4 text-xl font-semibold">
                  Instructor
                </div>
                <div class="flex items-center gap-5">
                  <div class="">
                    <img
                      src="@/assets/Signup.webp"
                      alt="profileLogo"
                      class="w-[5rem] md:w-[8rem] h-[5rem] md:h-[8rem] rounded-full"
                    />
                  </div>
                  <div>
                    <div
                      class="mb-[.5rem] font-bold text-xs md:text-lg text-start"
                    >
                      DR. Parthasarthy
                    </div>
                    <div
                      class="mb-[.5rem] font-bold text-xs md:text-lg text-start"
                    >
                      Tech Lead Mobile
                    </div>
                    <div class="font-bold text-xs md:text-lg text-start">
                      Manager
                    </div>
                  </div>
                </div>
                <div
                  class="mt-[2rem] w-[34rem] text-start text-sm hidden lg:block"
                >
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Ipsum sunt odit et provident repellendus est natus architecto
                  reiciendis doloremque ut officiis in porro aliquid fugiat quos
                  nam, itaque cum. Enim.
                </div>
              </div>
            </div>
            <div class="mt-[1rem] ml-2 lg:ml-8">
              <div class="w-max mb-[1rem] text-xl font-semibold">
                Related to this course
              </div>
              <div class="flex gap-4">
                <div>
                  <img src="@/assets/1.webp" alt="file" class="w-[1.2rem]" />
                </div>
                <div class="text-xs md:text-lg">
                  Exercise Files (2) .
                  <span class="text-blue-500">Show all</span>
                </div>
              </div>
              <div class="flex gap-4 mt-4">
                <div>
                  <img
                    src="@/assets/4.webp"
                    alt="certificate"
                    class="w-[1.2rem]"
                  />
                </div>
                <div class="text-xs md:text-lg">
                  Certificates <span class="text-blue-500">Show all</span>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!--deatailsDiv-->
      </div>
      <!--centerLayout-->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
export default {
  props: ["slug"],
  data() {
    return {
      activeTab: null,
      activeInnerTab: null,
      activeNabBar: false,
      courseTitle: "",
      courseContent: "",
      rightSidebarToggle: true,
      notesInput: "",
      notesTitle: "",
      notesData: [],
      courseTopicDetail: [],
      currentSectionIndex: 0,
      courseViewData: [],
      profileToggle: false,
    };
  },
  created() {
    this.courseViewInfo();
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    ...mapMutations(["setUserInfo"]),
    logout() {
      this.setUserInfo(null);
      this.$router.push({
        path: "/login",
      });
    },
    toggleTab(tabId) {
      if (this.activeTab === tabId) {
        this.activeTab = null;
      } else {
        this.activeTab = tabId;
        // this.activeInnerTab = null; // Reset inner tab when switching tabs
      }
    },
    toggleInnerTab(innerTabId) {
      if (this.activeInnerTab === innerTabId) {
        this.activeInnerTab = null;
      } else {
        this.activeInnerTab = innerTabId;
      }
    },

    async courseViewInfo() {
      await axios
        .get(`${process.env.VUE_APP_API}/course_detail/${this.slug}`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.courseViewData = resp.data.data;
            this.courseTopicDetail = this.getCourseTopics();
            this.activeTab = resp.data.data.section_lesson[0].id;
            this.activeInnerTab = this.courseTopicDetail[0].id;
            this.courseContent = this.courseTopicDetail[0].content;
            this.courseTitle = this.courseTopicDetail[0].title;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCourseTopics() {
      if (this.courseViewData.section_lesson.length > 0) {
        return this.courseViewData.section_lesson[this.currentSectionIndex]
          .Section;
      }
      return [];
    },

    notesHandler() {
      let notesDetail = {
        title: this.notesTitle,
        notes: this.notesInput,
      };

      this.notesData.push(notesDetail);

      (this.notesTitle = ""), (this.notesInput = "");
    },
    deleteNotes() {
      this.notesData = [];
    },
    editNotes(note) {
      console.log(note);
    },
    contentUpdate(content) {
      // Find the section index that contains the clicked inner tab
      const sectionIndex = this.courseViewData.section_lesson.findIndex(
        (section) => section.Section.some((tab) => tab.id === content.id)
      );

      // Update the currentSectionIndex only if it's different
      if (this.currentSectionIndex !== sectionIndex) {
        this.currentSectionIndex = sectionIndex;
        this.courseTopicDetail = this.getCourseTopics();
      }

      // Update the course content and title
      this.courseContent = content.content;
      this.courseTitle = content.title;
      this.activeInnerTab = content.id;
    },
    nextButtonClick() {
      const currentIndex = this.courseTopicDetail.findIndex(
        (tab) => tab.id === this.activeInnerTab
      );

      // Check if there is a next content in the current section
      if (currentIndex < this.courseTopicDetail.length - 1) {
        const nextContent = this.courseTopicDetail[currentIndex + 1];
        this.contentUpdate(nextContent);
      } else {
        // Check if there is a next section
        if (
          this.currentSectionIndex <
          this.courseViewData.section_lesson.length - 1
        ) {
          this.currentSectionIndex++;
          this.courseTopicDetail = this.getCourseTopics();
          this.contentUpdate(this.courseTopicDetail[0]); // Update with the first content of the new section
        } else {
          console.log("No more content");
        }
      }
    },

    PrevButtonClick() {
      let currentIndex = this.courseTopicDetail.findIndex(
        (tab) => tab.id === this.activeInnerTab
      );

      // Check if there is a previous content in the current section
      if (currentIndex > 0) {
        let prevContent = this.courseTopicDetail[currentIndex - 1];
        this.contentUpdate(prevContent);
      } else {
        // Check if there is a previous section
        if (this.currentSectionIndex > 0) {
          this.currentSectionIndex--;
          this.courseTopicDetail = this.getCourseTopics();
          const lastIndex = this.courseTopicDetail.length - 1;
          this.contentUpdate(this.courseTopicDetail[lastIndex]); // Update with the last content of the new section
        } else {
          console.log("No more content");
        }
      }
    },
  },
};
</script>

<style scoped>
.listStyle {
  list-style-type: circle;
}
li {
  list-style-type: circle;
}
</style>

<template>
  <div>
    <Header />
    <div class="max-w-screen-2xl m-auto">
      <div class="px-[3rem] 2xl:p-0 mb-4">
        <div class="md:w-max mt-8 ml-2 mb-4 text-2xl font-bold underline">
          Enrolled Courses
        </div>
        <div class="flex flex-wrap gap-5 justify-center md:justify-start" v-if="enrolledCourses != ''">
          <div class="mt-4" v-for="course in enrolledCourses" :key="course">
            <a :href="`/courseview/${course.course[0].slug}`">
              <div
                class="w-[20rem] h-[20rem] shadow-lg border border-gray-200 cursor-pointer rounded-md"
              >
                <div class="p-2">
                  <img
                    :src="course.course[0].banner"
                    alt="banner"
                    class="h-[10rem] object-cover rounded-md"
                  />
                </div>
                <div
                  class="mt-4 text-start ml-2 text-lg font-bold line-clamp-2"
                >
                  <p>
                    {{ course.course[0].title }}
                  </p>
                  <p class="text-sm font-normal line-clamp-2">
                    {{ course.course[0].short_title }}
                  </p>
                </div>
                <div class="mt-4 text-start ml-2 text-[#acacac] text-sm">
                  Beginner {{ course.course[0].max_durations }} hrs
                </div>
              </div>
            </a>
          </div>
        </div>
        <div v-else>
          <font-awesome-icon :icon="['fas', 'spinner']" spin class="text-4xl" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/component/Header.vue";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  components: {
    Header,
  },
  data() {
    return {
      enrolledCourses: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  created() {
    this.enrolledCoursesInfo();
  },
  methods: {
    async enrolledCoursesInfo() {
      if (this.userInfo) {
        await axios
          .get(
            `${process.env.VUE_APP_API}/userpermissionlist/?Key=${this.userInfo.token}`
          )
          .then((resp) => {
            if (resp.data.status == 200) {
              this.enrolledCourses = resp.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <Header />
    <div class="max-w-screen-2xl m-auto" v-if="courseInfoData != ''">
      <!--banner-->
      <div>
        <div class="relative">
          <!--web design-->
          <div class="p-[3rem] hidden lg:block">
            <img
              :src="courseInfoData.Image"
              alt="banner"
              class="w-[90rem] h-[22rem] xl:h-[25rem] 2xl:h-[27rem] rounded-xl object-cover"
            />
          </div>
          <!--web design-->
          <!--mob design-->
          <div class="lg:hidden bg-[#f8c311] h-[32rem]">
            <div class="pt-4 font-bold text-4xl text-black">
              {{ courseInfoData.title }}
            </div>
            <div class="pt-4 font-bold text-4xl text-black">Certification</div>
            <div class="flex justify-center gap-5 pt-4">
              <div class="flex flex-col items-center">
                <div class="w-[1rem]">
                  <img src="@/assets/1.webp" alt="Hours" />
                </div>
                <div class="font-semibold">
                  {{ courseInfoData.max_durations }} Hours
                </div>
              </div>
              <div class="flex flex-col items-center">
                <div class="w-[1rem]">
                  <img src="@/assets/1.webp" alt="Hours" />
                </div>
                <div class="font-semibold">Online Mode</div>
              </div>
            </div>
          </div>
          <!--mob design-->
          <div
            class="bg-white shadow-md p-[.5rem] absolute top-[34%] lg:top-[30%] 2xl:top-[28%] right-[7%] md:right-[24%] lg:right-[5%] 2xl:right-[8%]"
          >
            <div class="relative">
              <img
                :src="courseInfoData.mobile_banner"
                alt="bannerPlain"
                class="w-[20rem] md:w-[22rem] xl:w-[24rem] 2xl:w-[24rem] h-[10rem] 2xl:h-[13rem] object-cover"
              />
              <div>
                <img
                  src="@/assets/11.webp"
                  alt="11webp"
                  class="w-[5rem] absolute top-[30%] right-[40%]"
                />
              </div>
              <div
                class="text-white absolute bottom-[2%] xl:bottom-[8%] right-[24%] text-xl xl:text-2xl font-bold"
              >
                Preview this course
              </div>
            </div>
            <div class="mt-[1rem] mb-[1rem]">
              <a
                :href="`/courseview/${courseInfoData.slug}`"
                v-if="courseAccess"
              >
                <div
                  class="w-[20rem] md:w-[22rem] m-auto p-[.8rem] text-xl xl:text-3xl bg-black text-yellow-500 rounded-lg cursor-pointer"
                >
                  Start Learning
                </div>
              </a>
              <div
                class="w-[20rem] md:w-[22rem] m-auto p-[.8rem] text-xl xl:text-3xl bg-black text-yellow-500 rounded-lg cursor-pointer"
                v-else
                @click="userPermission"
              >
                Access this course
              </div>
              <!-- <router-link to="/courseview">
                <div
                  class="w-[22rem] m-auto p-[.8rem] text-xl xl:text-3xl bg-black text-yellow-500 rounded-lg cursor-pointer"
                >
                  Buy this course
                </div>
              </router-link> -->
            </div>
            <div
              class="flex justify-around items-center mt-3 xl:mt-6 mb-3 xl:mb-6"
            >
              <div class="font-semibold text-md">Full Lifetime Access</div>
              <div class="w-[1.5rem]">
                <img src="@/assets/10.webp" alt="share" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--banner-->
      <!--sliders-->
      <div class="mb-5 sticky top-[0px] z-10 w-full bg-white p-4">
        <div class="lg:ml-8 max-w-screen-2xl mx-auto">
          <div
            class="nav-bar list-none flex flex-row justify-start whitespace-nowrap overflow-x-auto gap-10 cursor-pointer border-b-2 lg:w-[40rem] pb-2 pl-2"
            style="scroll-snap-type: x mandatory"
          >
            <li
              @click="navBarActive"
              style="scroll-snap-align: start"
              class="text-sm font-bold"
              tabId="learning"
            >
              Learning
            </li>
            <li
              @click="navBarActive"
              style="scroll-snap-align: start"
              class="text-sm font-bold"
              tabId="CoursesFeatures"
            >
              Courses Features
            </li>
            <li
              @click="navBarActive"
              style="scroll-snap-align: start"
              class="text-sm font-bold"
              tabId="courseContent"
            >
              Content
            </li>
            <li
              @click="navBarActive"
              style="scroll-snap-align: start"
              class="text-sm font-bold"
              tabId="recommendation"
            >
              Recommendation
            </li>
          </div>
        </div>
      </div>
      <!--sliders-->
      <!--you will learn-->
      <div class="md:ml-[4rem] mt-[4rem] lg:mt-0" id="learning">
        <h1 class="w-max text-xl md:text-3xl ml-[6rem] md:ml-0 font-bold">
          WHAT YOU'LL LEARN
        </h1>
        <div
          class="h-[4px] bg-yellow-400 mt-3 w-[12rem] ml-[6rem] md:ml-0 md:w-[20rem]"
        ></div>
        <div class="w-[20rem] md:w-[40rem] xl:w-[45rem] 2xl:w-[50rem]">
          <div class="flex flex-wrap justify-between mt-5 ml-5 xl:ml-5 gap-3">
            <li class="md:w-[20rem] text-start text-md">
              Build a Marketing Agency
            </li>
            <li class="w-[18rem] text-start text-md">Price Your Services.</li>
            <li class="w-[20rem] text-start text-md">
              Build an Agency Website.
            </li>
            <li class="w-[18rem] text-start text-md">Get & Keep Clients.</li>
            <li class="w-[20rem] text-start text-md">Hire the Right People.</li>
            <li class="w-[18rem] text-start text-md">Customer Relationship.</li>
            <li class="w-[20rem] text-start text-md">Outsourcing.</li>
            <li class="w-[18rem] text-start text-md">
              Prompt Engineering with ChatGPT
            </li>
            <li class="w-[20rem] text-start text-md">
              Digital Marketing Beginner to Advanced.
            </li>
          </div>
        </div>
      </div>
      <!--you will learn-->
      <!--course Include-->
      <div class="md:ml-[2rem] mt-[2rem] mb-10" id="CoursesFeatures">
        <div
          class="bg-[#e4e4e4] p-[2rem] w-full md:w-[46rem] lg:w-[50rem] rounded-lg"
        >
          <h1 class="w-max text-xl md:text-3xl ml-[3rem] md:ml-0 font-bold">
            THIS COURSE INCLUDES:
          </h1>
          <div
            class="h-[4px] bg-yellow-400 mt-1 w-[16rem] md:w-[22rem] ml-[3rem] md:ml-0"
          ></div>
          <div class="flex flex-wrap md:ml-[2.5rem] gap-2 md:gap-5 mt-4 mb-4">
            <div class="flex items-center w-[10rem] md:w-[18rem] lg:w-[20rem]">
              <div class="w-[1rem]">
                <img src="@/assets/1.webp" alt="video" />
              </div>
              <div class="ml-2 md:ml-[1rem] text-xs md:text-lg text-start">
                86.5 hours on-demand video
              </div>
            </div>

            <div class="flex items-center w-[10rem] md:w-[18rem] lg:w-[20rem]">
              <div class="w-[1rem]">
                <img src="@/assets/5.webp" alt="downloadResources" />
              </div>
              <div class="ml-2 md:ml-[1rem] text-xs md:text-lg text-start">
                98 downloadable resources
              </div>
            </div>

            <div class="flex items-center w-[10rem] md:w-[18rem] lg:w-[20rem]">
              <div class="w-[1rem]">
                <img src="@/assets/2.webp" alt="assignment" />
              </div>
              <div class="ml-2 md:ml-[1rem] text-xs md:text-lg text-start">
                Assignments
              </div>
            </div>

            <div class="flex items-center w-[10rem] md:w-[18rem] lg:w-[20rem]">
              <div class="w-[1rem]">
                <img src="@/assets/6.webp" alt="accessTv" />
              </div>
              <div class="ml-2 md:ml-[1rem] text-xs md:text-lg text-start">
                Access on mobile and TV
              </div>
            </div>

            <div class="flex items-center w-[10rem] md:w-[18rem] lg:w-[20rem]">
              <div class="w-[1rem]">
                <img src="@/assets/3.webp" alt="articles" />
              </div>
              <div class="ml-2 md:ml-[1rem] text-xs md:text-lg text-start">
                41 articles
              </div>
            </div>

            <div class="flex items-center w-[10rem] md:w-[18rem] lg:w-[20rem]">
              <div class="w-[1rem]">
                <img src="@/assets/4.webp" alt="courseCompletion" />
              </div>
              <div class="ml-2 md:ml-[1rem] text-xs md:text-lg text-start">
                Certificate of completion
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--course Include-->
      <!---Course Content-->
      <div class="mt-[3rem]" id="courseContent">
        <div class="md:ml-[4rem]">
          <h1 class="w-max text-xl ml-[6rem] md:ml-0 md:text-3xl font-bold">
            COURSE CONTENT
          </h1>
          <div
            class="h-[4px] bg-yellow-400 ml-[6rem] md:ml-0 mt-1 w-[12rem] md:w-[17rem]"
          ></div>
          <div
            class="w-max mt-4 font-light ml-[2rem] md:ml-0 text-sm md:text-md md:text-xl"
          >
            19 sections . 130 lectures . 20h 34m total length
          </div>
        </div>
        <div
          class="w-full md:w-[44rem] lg:w-[50rem] mt-[1rem] mb-[2rem] md:ml-[3rem]"
        >
          <div v-for="tabs in courseInfoData.section_lesson" :key="tabs.id">
            <div
              class="flex justify-between p-[.8rem] mb-4 cursor-pointer"
              :class="[activeTab == tabs.id ? 'bg-yellow-400' : 'bg-[#f3f3f3]']"
              @click="toggleTab(tabs.id)"
            >
              <div class="ml-2 md:ml-[1rem] font-bold text-sm md:text-lg">
                {{ tabs.title }}
                <span>
                  <font-awesome-icon
                    icon="fa-solid fa-chevron-up"
                    class="text-lg md:text-xl cursor-pointer"
                    v-if="activeTab == tabs.id"
                  />
                  <font-awesome-icon
                    icon="fa-solid fa-chevron-down"
                    class="text-lg md:text-xl cursor-pointer"
                    v-else
                  />
                </span>
              </div>
              <div class="text-sm md:text-lg font-smeibold">4hrs</div>
            </div>
            <div class="p-[.6rem]" v-if="activeTab == tabs.id">
              <div
                class="flex justify-between"
                v-for="innerTabs in tabs.Section"
                :key="innerTabs.id"
              >
                <div class="flex gap-4 mb-5">
                  <div class="w-[1rem] md:w-[1.5rem] ml-4">
                    <img src="@/assets/1.webp" alt="demo" />
                  </div>
                  <div class="w-max text-start text-sm">
                    {{ innerTabs.title }}
                  </div>
                </div>
                <div class="text-sm md:text-lg">3hr</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          class="w-max ml-[6rem] md:ml-[4rem] text-xl md:text-2xl underline cursor-pointer"
        >
          See all Sections
        </div> -->
      </div>
      <!---Course Content-->
      <!--instructor Details-->
      <!-- <div class="mt-[3rem] md:ml-[4rem]">
        <h1 class="w-max ml-[5rem] text-xl md:text-3xl font-bold">
          INSTRUCTOR DETAILS
        </h1>
        <div
          class="h-[4px] ml-[5rem] bg-yellow-400 mt-1 w-[13rem] md:w-[21rem]"
        ></div>
        <div class="mt-[2rem] ml-4 md:ml-0">
          <div class="flex items-center gap-5">
            <div class="">
              <img
                src="@/assets/Signup.webp"
                alt="profileLogo"
                class="w-[8rem] md:w-[14rem] h-[8rem] md:h-[14rem] rounded-full"
              />
            </div>
            <div>
              <div
                class="md:mb-[1rem] font-bold text-base md:text-2xl text-start"
              >
                DR. Parthasarthy
              </div>
              <div
                class="md:mb-[1rem] font-bold text-base md:text-2xl text-start"
              >
                Teach Lead Mobile
              </div>
              <div class="font-bold text-base md:text-2xl text-start">
                Manager
              </div>
            </div>
          </div>
          <div
            class="mt-[2rem] w-[20rem] md:w-[34rem] text-start text-sm md:text-xl ml-8 md:ml-0"
          >
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum sunt
            odit et provident repellendus est natus architecto reiciendis
            doloremque ut officiis in porro aliquid fugiat quos nam, itaque cum.
            Enim.
          </div>
        </div>
      </div> -->
      <!--instructor Details-->
      <!--Certificate-->
      <div class="mt-[3rem] md:ml-[4rem] mb-[3rem]">
        <h1 class="w-max text-xl md:text-3xl font-bold ml-[4rem] md:ml-0">
          SAMPLE CERTIFICATE
        </h1>
        <div
          class="h-[4px] bg-yellow-400 mt-1 w-[14rem] md:w-[21rem] ml-[4rem] md:ml-0"
        ></div>
        <div class="mt-[1rem] w-[20rem] md:w-max text-sm md:text-xl ml-[2rem]">
          Digital Marketing Certificate with a lifetime validity
        </div>
        <div class="mt-[1rem] p-[1rem] md:p-0">
          <div><img src="@/assets/jainCertificate.jpg" alt="certficate" /></div>
        </div>
      </div>
      <!--Certificate-->
      <div class="mb-8" id="recommendation">
        <OnlineDegree></OnlineDegree>
      </div>
    </div>
    <div class="mt-[18rem]" v-else>
      <font-awesome-icon :icon="['fas', 'spinner']" spin class="text-4xl" />
    </div>
  </div>
</template>

<script>
import Header from "@/component/Header.vue";
import OnlineDegree from "@/component/OnlineDegree.vue";
import { mapGetters } from "vuex";
import axios from "axios";
import $ from "jquery";

export default {
  name: "courseDetail",
  components: {
    Header,
    OnlineDegree,
  },
  props: ["course_slug"],
  data() {
    return {
      activeTab: null,
      courseInfoData: [],
      courseTitle: [],
      courseId: [],
      courseAccess: false,
      courseInnerList: [],
    };
  },
  async created() {
    $(document).on("scroll", this.onScroll);

    await this.courseInfo();
    this.userPermissionList();
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    async courseInfo() {
      await axios
        .get(`${process.env.VUE_APP_API}/course_detail/${this.course_slug}`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.courseInfoData = resp.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async userPermission() {
      this.courseTitle.push(this.courseInfoData.title);
      this.courseId.push(this.courseInfoData.id);
      let data = {
        user: this.userInfo.user.id,
        permission_path: this.courseTitle,
        course: this.courseId,
      };
      let url = `${process.env.VUE_APP_API}/userpermission/?Key=${this.userInfo.token}`;
      await axios
        .post(url, data)
        .then((resp) => {
          if (resp.status == 200) {
            setTimeout(() => {
              this.$router.push({
                name: "courseview",
                params: { slug: this.courseInfoData.slug },
              });
            }, 1000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async userPermissionList() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/userpermissionlist/?Key=${this.userInfo.token}`
        )
        .then((resp) => {
          console.log(
            resp.data.data.map((item) =>
              item.course.map((item) => item.id == this.courseInfoData.id)
            )
          );
          if (resp.data.status == 200) {
            if (
              resp.data.data.some((item) =>
                item.course.some((item) => item.id == this.courseInfoData.id)
              )
            ) {
              this.courseAccess = true;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    toggleTab(tabId) {
      if (this.activeTab === tabId) {
        this.activeTab = null;
      } else {
        this.activeTab = tabId;
      }
    },

    navBarActive(event) {
      this.navClick = true;
      $(".nav-bar li").removeClass("active");
      var targetItem = $(event.target);
      targetItem.addClass("active");

      $("html, body").animate(
        {
          scrollTop:
            $(`#${targetItem.attr("tabId")}`).offset().top -
            $(".nav-bar").height() -
            80,
        },
        1000
      );

      setTimeout(() => (this.navClick = false), 5000);
    },
    onScroll() {
      if (!this.navClick) {
        var scrollPos = $(document).scrollTop();
        $(".nav-bar li").each(function () {
          var currLink = $(this);
          var refElement = $(`#${currLink.attr("tabId")}`);
          if (
            refElement.position().top - $(".nav-bar").height() - 80 <=
              scrollPos &&
            refElement.position().top -
              $(".nav-bar").height() -
              80 +
              refElement.height() >
              scrollPos
          ) {
            $(".nav-bar li.active").removeClass("active");
            currLink.addClass("active");
          } else {
            currLink.removeClass("active");
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.nav-bar .active {
  color: #110b7c !important;
  font-weight: 600;
  border-bottom: 3px solid #110b7c;
}
</style>
